<template>
  <div>
    <DocumentTemplates :preview="preview" :template="template" />
    <br />
    <div v-for="(section, idx) in sections" :key="idx">
      <PreviewSection
        :questionnaire="questionnaireWrapper"
        :section="section"
        :level="1"
        :debug="debug"
      />
      <br />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DeepQuestionnaire,
  DeepQuestionnaireTemplate,
  ItemAnswer,
  QuestionnaireRoot,
  ValueQuestion,
} from '@dims/components';
import { computed, ref } from 'vue';
import DocumentTemplates from './DocumentTemplates.vue';
import PreviewSection from './PreviewSection.vue';

const emit = defineEmits<{ updatePreview: [] }>();
const { preview, template, debug = false } = defineProps<{
  preview: DeepQuestionnaire,
  template: DeepQuestionnaireTemplate,
  debug?: boolean }>();

class PreviewQuestionnaireWrapper implements QuestionnaireRoot {
  constructor(readonly questionnaire: DeepQuestionnaire, readonly onUpdate: () => void) { }

  get id() { return this.questionnaire.id; }
  get complete() { return this.questionnaire.complete; }
  get tenderId() { return this.questionnaire.tenderId; }
  questionUpdated(_question: ValueQuestion) {
    this.onUpdate();
  }
  /* Not supported in preview: */
  questionnareChanged(_questionnaire: DeepQuestionnaire) { return Promise.resolve(); }
  addRepeaterItem(_questionId: string, _init: ItemAnswer[]): Promise<string> {
    return Promise.reject<string>(new Error());
  }
  deleteRepeaterItem(_questionId: string): Promise<void> {
    return Promise.reject(new Error());
  }
}

const questionnaireWrapper = ref<QuestionnaireRoot>(
  new PreviewQuestionnaireWrapper(
    preview,
    () => { emit('updatePreview'); },
  ),
);

const sections = computed(() => preview.questions);

</script>
<style scoped>
.pre-formatted {
  white-space: pre-wrap !important;
}
</style>
